import axios from 'axios';
import config from '@config';
import { Logger } from '@storefront/core/lib/logger';

import { getCurrentLanguage } from '@storefront/core/i18n';

const state = () => ({
  blogCategories: [],
  filteredBlogs: [],
});

const actions = {
  async blogCategories() {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang['storeview'];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };

    const query =
      '{ blogCategories { items { category_id identifier posts_count title } total_count } }';

    const retval = await axios({
      url: config.shop.graphQLURL + '?query=' + encodeURI(query),
      method: 'GET',
      headers: headers,
      //data: { query: query },
    }).catch((e) => {
      Logger.error('blog', 'loadBlogCategories', e)();
      console.log(e);
      throw e;
    });

    if (retval.data.data.blogCategories != null) {
      return retval.data.data.blogCategories;
    } else {
      return false;
    }
  },
  async blogsByFilter(_, { categoryId, searchVal, currentPage, pageSize }) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang['storeview'];

    const headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + config.shop.accessToken,
      Store: storeview,
    };

    let filter = ' filter: {';

    if (categoryId) {
      filter += ' category_id: { eq : "' + categoryId + '" } ';
    }

    if (searchVal) {
      filter += ' search: { eq : "' + searchVal + '" } ';
      // filter += ' title: { like : "%' + searchVal + '%" } ';
    }

    filter += '} ';

    const query =
      '{ blogPosts( pageSize: ' +
      pageSize +
      ' currentPage: ' +
      currentPage +
      filter +
      ' ){ items{ identifier post_id title short_filtered_content featured_image featured_img_alt first_image creation_time author { identifier author_id name author_url } } total_count total_pages } }';

    const retval = await axios({
      url: config.shop.graphQLURL + '?query=' + encodeURI(query),
      method: 'GET',
      headers: headers,
      //data: { query: query },
    }).catch((e) => {
      Logger.error('blog', 'blogsByFilter', e)();
      console.log(e);
      throw e;
    });

    if (retval.data.data.blogPosts != null) {
      return retval.data.data.blogPosts;
    } else {
      return false;
    }
  },
  async loadBlogCategories({ dispatch, commit }) {
    const retval = await dispatch('blogCategories');
    if (retval != false) {
      commit('setBlogCategories', retval.items);
    }
  },
  async loadBlogsByFilter(
    { dispatch, commit, rootGetters },
    { categoryId, searchVal }
  ) {
    const pageSize = rootGetters['blog/getBlogsPerPage'];
    const retval = await dispatch('blogsByFilter', {
      categoryId,
      searchVal,
      pageSize,
      currentPage: 1,
    });

    if (retval != false) {
      commit('resetFilterdBlogs');
      commit('setFilterdBlogs', retval.items);
      const totalPages = retval.total_pages;
      if (totalPages > 1) {
        for (let page = 2; page <= totalPages; page++) {
          const retval1 = await dispatch('blogsByFilter', {
            categoryId,
            searchVal,
            pageSize,
            currentPage: page,
          });
          commit('setFilterdBlogs', retval1.items);
        }
      }
    }
  },
};

const mutations = {
  setBlogCategories(state, payload) {
    state.blogCategories = payload;
  },
  setFilterdBlogs(state, payload) {
    payload.forEach((element) => {
      state.filteredBlogs.push(element);
    });
  },
  resetFilterdBlogs(state) {
    state.filteredBlogs = [];
  },
};

const getters = {
  getBlogCategories: (state) => state.blogCategories,
  getFilteredBlogs: (state) => state.filteredBlogs,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
