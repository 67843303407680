import { getBestSellers } from "@storefront/core/data-resolver/categories";
import {
  getProductFiltersByFilteredCategory,
  getProductByFilteredCategory,
  getProductFiltersByCategory,
} from "@storefront/core/data-resolver/products";
import { loadSliderByCategoryID } from "@storefront/core/data-resolver/sliders";
import store from "@/store";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import { isServer } from "@storefront/core/helpers";

const state = () => ({
  current: null,
  slider: null,
  menuCategories: [],
  productFilters: [],
  activeFilter: [],
  priceFilter: [0, 0],
  priceFilterMin: 0,
  priceFilterMax: 0,
  filteredCount: 0,
  products: [],
  ShowProducts: [],
  currentPage: 1,
  prodsPerPage: null,
  totalPages: 1,
  totalProducts: 0,
  sorting: "default",
  currentSorting: null,
  bestSellers: {},
  bestSellersSimple: [],
  loadProducts: false,
  gtmProducts: [],
  ga4Products: [],
  gtmProductsCount: 0,
});

const actions = {
  /**
   * load category slider from magento
   *
   *
   */
  async loadSlider({ getters, commit }) {
    const cat = getters["getCurrentCategory"];
    const retv = await loadSliderByCategoryID(cat.id);
    commit("setSlider", retv);
  },
  /**
   * Load products by filtered category
   *
   *
   * @param {object} integer category
   */
  async loadProductsByFilter({ getters, commit }) {
    commit("setLoadProducts", true);

    const categoryId = getters["getCurrentCategoryId"];

    const currPage = getters["getCurrentPage"];
    const perPage = getters["getProductsPerPage"];
    const totalPages = getters["getTotalPages"];

    if (currPage > totalPages) return;

    const sort = getters["getCurrentSorting"];

    const filters = [];

    const isPriceFilterActive = getters["getIsPriceFilterActive"];

    if (isPriceFilterActive) {
      const priceFilter = getters["getPriceFilter"];
      filters.push({ attribute_code: "price", values: priceFilter });
    }

    const activeFilters = getters["getActiveFilter"];

    activeFilters.forEach((el) => {
      const index = filters.findIndex(
        (o) => o.attribute_code == el.attribute_code
      );

      if (index != -1) {
        filters[index].values.push(el.value);
      } else {
        filters.push({ attribute_code: el.attribute_code, values: [el.value] });
      }
    });

    const retv = await getProductByFilteredCategory(
      categoryId,
      currPage,
      perPage,
      {
        sort,
        filters,
      }
    );

    commit("setProducts", retv.items);

    commit("setLoadProducts", false);

    let catStr = "";
    const bcrumbs = store.getters["breadcrumbs/getBreadcrumbsRoutes"];
    bcrumbs.forEach((bcrumb) => {
      catStr = catStr + bcrumb.name + "/";
    });
    const curr = store.getters["breadcrumbs/getBreadcrumbsCurrent"];
    catStr = catStr + curr;
    //gtm tracking info
    let gtmProds = [];
    let ga4Prods = [];
    retv.items.forEach((element) => {
      let gtmProd = {};
      let ga4Prod = {};
      gtmProd.id = element.sku;
      gtmProd.name = element.name;
      gtmProd.list = "Category";
      gtmProd.category = catStr;
      commit("setGtmProductsCount");
      gtmProd.position = getters["getGtmProductsCount"];
      gtmProds.push(gtmProd);
      ga4Prod.id = element.sku;
      ga4Prod.name = element.name;
      ga4Prod.price = element.price_range.maximum_price.final_price.value;
      if (element.price_range.maximum_price.discount.amount_off > 0) {
        ga4Prod.discount =
          element.price_range.maximum_price.discount.amount_off;
      }
      ga4Prod.position = getters["getGtmProductsCount"];
      ga4Prods.push(ga4Prod);
    });
    commit("setGtmProducts", gtmProds);
  },
  /**
   * load bestsellers based on current category
   *
   */
  async loadBestSellers({ getters, commit }) {
    const category = getters["getCurrentCategory"];
    const retval = await getBestSellers(category.id);
    if (retval != false) {
      commit("setBestSellers", retval);
      const simpleBestSellers = [];
      retval.tabs.forEach((element) => {
        element.items.forEach((elm) => {
          simpleBestSellers.push(elm);
        });
      });
      commit("setBestSellersSimple", simpleBestSellers);
    }
  },
  /**
   * load category filters based on id of the category
   *
   * @param {object} integer id
   */
  async loadCategoryFilters({ commit, getters }) {
    commit("setLoadProducts", true);
    const categoryId = getters["getCurrentCategoryId"];

    const sort = getters["getCurrentSorting"];

    const filters = [];

    const isPriceFilterActive = getters["getIsPriceFilterActive"];

    if (isPriceFilterActive) {
      const priceFilter = getters["getPriceFilter"];
      filters.push({ attribute_code: "price", values: priceFilter });
    }

    const activeFilters = getters["getActiveFilter"];

    activeFilters.forEach((el) => {
      const index = filters.findIndex(
        (o) => o.attribute_code == el.attribute_code
      );

      if (index != -1) {
        filters[index].values.push(el.value);
      } else {
        filters.push({ attribute_code: el.attribute_code, values: [el.value] });
      }
    });

    const currPage = getters["getCurrentPage"];
    const perPage = getters["getProductsPerPage"];

    const retval = await getProductFiltersByFilteredCategory(
      categoryId,
      currPage,
      perPage,
      { sort, filters }
    ).catch((e) => {
      Logger.error(
        "getProductFiltersByFilteredCategory",
        "category store actions load",
        e
      )();
      throw e;
    });

    const products = retval.items;
    const totalPages = retval.page_info.total_pages;
    const totalProducts = retval.total_count;

    commit("setTotalPages", totalPages);
    commit("setTotalProducts", totalProducts);
    commit("setProducts", products);

    commit("setLoadProducts", false);

    const uFilters = [];

    if (retval.aggregations && retval.aggregations.length) {
      retval.aggregations.forEach((element) => {
        if (element.attribute_code != "category_id") {
          if (element.attribute_code == "price") {
            if (parseInt(element.min_value) < 0) {
              element.min_value = 0;
            }
            element.min = parseInt(element.min_value);
            if (Math.ceil(element.max_value) < element.min_value) {
              element.max = element.min_value;
            } else {
              element.max = Math.ceil(element.max_value);
            }
            if (element.min < 0) {
              element.min = 0;
            }
            if (element.max < 0) {
              element.max = 0;
            }

            const priceFilter = getters["getPriceFilter"];

            if (priceFilter[0] === 0 && priceFilter[1] === 0) {
              commit("setPriceFilterMin", element.min);
              commit("setPriceFilterMax", element.max);
              commit("addPriceFilter", [element.min, element.max]);
            }
          }
          uFilters.push(element);
        }
      });
    }
    commit("setProductFilters", uFilters);
  },

  /**
   * apply category filters of the category
   *
   * @param {object}
   * @param {object} filter
   */
  async applyCategoryFilters({ commit, dispatch, getters }, filter) {
    const categoryId = getters["getCurrentCategoryId"];

    const { sort, page, filters } = filter;

    let { pageSize } = filter;
    if (isNaN(parseInt(pageSize))) pageSize = 12;

    const retval = await getProductFiltersByCategory(categoryId, pageSize);

    const totalPages = retval.page_info.total_pages;
    const totalProducts = retval.total_count;

    commit("setTotalPages", totalPages);
    commit("setTotalProducts", totalProducts);

    const priceFilter = [0, 0];
    const productFilters = [];

    if (retval.aggregations && retval.aggregations.length) {
      retval.aggregations.forEach((element) => {
        if (element.attribute_code != "category_id") {
          if (element.attribute_code == "price") {
            if (parseInt(element.min_value) < 0) {
              element.min_value = 0;
            }
            element.min = parseInt(element.min_value);
            if (Math.ceil(element.max_value) < element.min_value) {
              element.max = element.min_value;
            } else {
              element.max = Math.ceil(element.max_value);
            }
            if (element.min < 0) {
              element.min = 0;
            }
            if (element.max < 0) {
              element.max = 0;
            }

            priceFilter[0] = element.min;
            priceFilter[1] = element.max;

            commit("setPriceFilterMin", element.min);
            commit("setPriceFilterMax", element.max);
            commit("addPriceFilter", priceFilter);
          }
          productFilters.push(element);
        }
      });
    }
    commit("setProductFilters", productFilters);

    if (!isNaN(parseInt(page)) && parseInt(page) <= totalPages) {
      commit("addCurrentPage", page);
    }

    if (!isNaN(parseInt(pageSize))) {
      commit("addProductsPerPage", pageSize);
    } else {
      commit("addProductsPerPage", 12);
    }

    if (sort) commit("addSorting", sort);

    const activeFilters = [];
    let activePriceFilter = [];

    if (filters) {
      Object.keys(filters).forEach((element) => {
        const attrFilters = productFilters.find(
          (el) => el.attribute_code == element
        );

        if (attrFilters != null) {
          if (element == "price") {
            const [priceMin, priceMax] = priceFilter;
            const [min, max] = filters["price"].split("-");
            if (!isNaN(parseInt(min)) && !isNaN(parseInt(max))) {
              activePriceFilter = [
                priceMin > parseInt(min) ? priceMin : parseInt(min),
                priceMax < parseInt(max) ? priceMax : parseInt(max),
              ];
            }
          } else {
            filters[element].split(",").forEach((value) => {
              const activeFilter = attrFilters.options.find(
                (option) => option.value == value
              );
              if (activeFilter) {
                const filterBy = {
                  attribute_code: element,
                  type_label: attrFilters.label,
                  value: value,
                  label: activeFilter.label,
                };
                activeFilters.push(filterBy);
              }
            });
          }
        }
        
      });
      if(filters.subcategory){
        filters['subcategory'].split(",").forEach((value) => { /// subcat id
            /// activeFilter : option object  ->  { count, label, value }
            const filterBy = {
              attribute_code: 'subcategory',
              type_label: 'subcategory',
              value: value,
              label: value,
            };
            activeFilters.push(filterBy);
          
        });
      }
    }

    if (activeFilters.length) commit("addActiveFilter", activeFilters);
    if (activePriceFilter.length) commit("addPriceFilter", activePriceFilter);

    if (activeFilters.length || activePriceFilter.length) {
      const filters = [];
      const isPriceFilterActive = getters["getIsPriceFilterActive"];
      if (isPriceFilterActive) {
        const priceFilter = getters["getPriceFilter"];
        filters.push({ attribute_code: "price", values: priceFilter });
      }
      const activeFilters = getters["getActiveFilter"];
      activeFilters.forEach((el) => {
        const index = filters.findIndex(
          (o) => o.attribute_code == el.attribute_code
        );

        if (index != -1) {
          filters[index].values.push(el.value);
        } else {
          filters.push({
            attribute_code: el.attribute_code,
            values: [el.value],
          });
        }
      });

      const retval = await getProductFiltersByCategory(
        categoryId,
        pageSize,
        filters
      ).catch((e) => {
        Logger.error(
          "getProductFiltersByCategory",
          "category store actions load",
          e
        )();
        throw e;
      });

      const currentPage = getters["getCurrentPage"];
      const totalPages = retval.page_info.total_pages;
      const totalProducts = retval.total_count;

      commit("setTotalPages", totalPages);
      commit("setTotalProducts", totalProducts);

      if (currentPage > totalPages) commit("addCurrentPage", 1);

      const uFilters = [];

      if (retval.aggregations && retval.aggregations.length) {
        retval.aggregations.forEach((element) => {
          if (element.attribute_code != "category_id") {
            if (element.attribute_code == "price") {
              if (parseInt(element.min_value) < 0) {
                element.min_value = 0;
              }
              element.min = parseInt(element.min_value);
              if (Math.ceil(element.max_value) < element.min_value) {
                element.max = element.min_value;
              } else {
                element.max = Math.ceil(element.max_value);
              }
              if (element.min < 0) {
                element.min = 0;
              }
              if (element.max < 0) {
                element.max = 0;
              }

              const priceFilter = getters["getPriceFilter"];

              if (priceFilter[0] === 0 && priceFilter[1] === 0) {
                commit("setPriceFilterMin", element.min);
                commit("setPriceFilterMax", element.max);
                commit("addPriceFilter", [element.min, element.max]);
              }
            }
            uFilters.push(element);
          }
        });
      }
      commit("setProductFilters", uFilters);
    }
    await dispatch("loadProductsByFilter");
  },

  /**
   * load the category
   *
   * @param {object} integer id
   * @returns category object
   * @deprecated replaced by setCategory dispatch because of the route update
   */
  async setCategory({ commit, dispatch }, { category, filter = false }) {
    commit("setLoadProducts", false);
    commit("setBestSellersSimple", []);
    commit("setBestSellers", []);

    if (category == null) {
      commit("setProductFilters", {});
      return false;
    } else {
      if (category.description) {
        category.description = category.description.replace(
          /<a href=/g,
          "<b-link to="
        );
        category.description = category.description.replace(/<a/g, "<b-link");
        category.description = category.description.replace(
          /<\/a>/g,
          "</b-link>"
        );
        category.description = category.description.replace(
          / href="\//g,
          ' to="/'
        );
        category.description = category.description.replace(
          /<b-link((?!<\/b-link>).)to="(http|mailto|tel)(.*?)<\/b-link>/g,
          '<a$1href="$2$3</a>'
        );
      }

      commit("setCurrentCategory", category);
      dispatch("loadSlider");
      if (category.is_anchor == 1) {
        commit("setProducts", []);
        commit("setProductFilters", {});
        commit("resetActiveFilter");
        commit("resetPriceFilter");
        commit("resetPageInfo");
        commit("resetSorting");
        commit("setConfigSettings");

        if (filter !== false) {
          await dispatch("applyCategoryFilters", filter);
        } else {
          await dispatch("loadCategoryFilters");
        }

        if (!isServer) {
          dispatch("loadBestSellers");
        }
      } else {
        commit("setProductFilters", {});
        commit("resetActiveFilter");
        commit("resetPriceFilter");
        commit("resetPageInfo");
        commit("resetSorting");
        commit("setBestSellersSimple", []);
      }
    }
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  /**
   * set current category
   *
   * @param {object} state
   * @param {object} data
   * @private
   *
   */
  setCurrentCategory(state, data) {
    state.current = data;
    const breadcrumbs = data.breadcrumbs;
    let currentName = "undefined";
    if (typeof data.name == "string") {
      currentName = data.name;
    }
    const bcrumb = { current: currentName, routes: [] };
    if (breadcrumbs != null) {
      breadcrumbs.sort((a, b) => {
        if (a.category_level < b.category_level) {
          return -1;
        }
        if (a.category_level > b.category_level) {
          return 1;
        }
        return 0;
      });
      let path = "";
      breadcrumbs.forEach((element) => {
        if (path.length > 0) {
          path = path + "/";
        }
        path = path + element.category_url_key;
        let name = "undefined";
        if (typeof element.category_name == "string") {
          name = element.category_name;
        }
        const bc = {
          name: name,
          route_link: "/" + path,
        };
        bcrumb.routes.push(bc);
      });
    }
    //const bcrumb = { current: data.name, routes: routes };
    store.commit("breadcrumbs/set", bcrumb);
  },
  /**
   * set default settings
   *
   * @param {object} state
   * @private
   *
   */
  setConfigSettings(state) {
    if (state.prodsPerPage === null) {
      if (config.products?.perPage) {
        state.prodsPerPage = config.products.perPage;
      } else {
        state.prodsPerPage = 12;
      }
    }

    let sorting = "default";
    const defaultSort = config.sortDefault;
    if (defaultSort) sorting = defaultSort;
    store.commit("category/addSorting", sorting);
  },
  /**
   * set product filters
   *
   * @param {object} state
   * @param {object} data
   * @private
   *
   */
  setProductFilters(state, data) {
    state.productFilters = data;
  },
  /**
   * set category slider
   *
   * @param {object} state
   * @param {object} data
   * @private
   *
   */
  setSlider(state, data) {
    state.slider = data;
  },
  /**
   * set load products
   *
   * @param {object} state
   * @param {array} data
   * @private
   *
   */
  setLoadProducts(state, data) {
    state.loadProducts = data;
  },
  /**
   * add active filter
   *
   * @param {object} state
   * @param {object} data
   */
  addActiveFilter(state, data) {
    state.activeFilter = data;
  },
  /**
   * reset active filter
   *
   * @param {object} state
   */
  resetActiveFilter(state) {
    state.activeFilter = [];
  },
  /**
   * set products
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setProducts(state, data) {
    state.products = data;
    state.ShowProducts = data;
  },
  /**
   * add currentPage
   *
   * @param {object} state
   * @param {integer} data
   * @private
   */
  addCurrentPage(state, data) {
    if (data >= 1) {
      state.currentPage = data;
    }
  },
  /**
   * set currentPage
   *
   * @param {object} state
   * @param {integer} data
   * @private
   */
  setCurrentPage(state, data) {
    if (data >= 1 && data <= state.totalPages) {
      state.currentPage = data;
      store.dispatch("category/loadProductsByFilter");
    }
  },
  resetCurrentPage(state) {
    state.currentPage = 1;
  },
  /**
   * add productPerPage
   *
   * @param {object} state
   * @param {integer} data
   * @private
   */
  addProductsPerPage(state, data) {
    state.prodsPerPage = data;
  },
  /**
   * set productPerPage
   *
   * @param {object} state
   * @param {integer} data
   * @private
   */
  setProductsPerPage(state, data) {
    state.prodsPerPage = data;
    store.commit("category/resetCurrentPage");

    const totalProducts = state.totalProducts;
    const totalPages = Math.ceil(state.totalProducts / data);

    state.totalPages = totalPages;

    if (totalProducts > 0) {
      store.dispatch("category/loadProductsByFilter");
    }
  },
  /**
   * set totalPages
   *
   * @param {object} state
   * @param {integer} data
   * @private
   */
  setTotalPages(state, data) {
    state.totalPages = data;
  },
  /**
   * set totalProducts
   *
   * @param {object} state
   * @param {integer} data
   * @private
   */
  setTotalProducts(state, data) {
    state.totalProducts = data;
  },
  /**
   * reset totalProducts
   *
   * @param {object} state
   * @private
   */
  resetPageInfo(state) {
    state.currentPage = 1;
    state.totalPages = 1;
    state.totalProducts = 0;
  },
  /**
   * add products
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  addProducts(state, data) {
    data.forEach((element) => {
      const retval = state.products.find((o) => {
        if (o.sku == element.sku) {
          return true;
        }
      });
      if (retval == null) {
        state.products.push(element);
      }
    });
    store.commit("category/doFilterProducts");
  },
  /**
   * add price filter
   *
   * @param {object} state
   * @param {array} data
   */
  addPriceFilter(state, data) {
    state.priceFilter = data;
  },
  /**
   * set price filter
   *
   * @param {object} state
   * @param {array} data
   */
  setPriceFilter(state, data) {
    state.priceFilter = data;
    store.commit("category/resetCurrentPage");
    store.dispatch("category/loadCategoryFilters");
  },
  /**
   * reset price filter
   *
   * @param {object} state
   */
  resetPriceFilter(state) {
    state.priceFilter = [0, 0];
  },
  /**
   * set min price
   *
   * @param {object} state
   * @param {integer} data
   */
  setPriceFilterMin(state, data) {
    state.priceFilterMin = data;
  },
  /**
   * set max price
   *
   * @param {object} state
   * @param {integer} data
   */
  setPriceFilterMax(state, data) {
    state.priceFilterMax = data;
  },
  /**
   * remove price filter
   *
   * @param {object} state
   */
  removePriceFilter(state) {
    const priceMin = state.priceFilterMin;
    const priceMax = state.priceFilterMax;
    state.priceFilter = [priceMin, priceMax];
  },
  /**
   * set bestsellers
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setBestSellers(state, data) {
    state.bestSellers = data;
  },
  /**
   * set bestsellers
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setGtmProducts(state, data) {
    state.gtmProducts = data;
  },
  /**
   * set Google Analytics 4 product list
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setGa4Products(state, data) {
    state.ga4Products = data;
  },
  /**
   * set bestsellers
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setGtmProductsCount(state) {
    state.gtmProductsCount = state.gtmProductsCount + 1;
    return state.gtmProductsCount;
  },

  resetGtmProductsCount(state) {
    state.gtmProductsCount = 0;
  },
  /**
   * set simple bestsellers
   *
   * @param {object} state
   * @param {array} data
   * @private
   */
  setBestSellersSimple(state, data) {
    state.bestSellersSimple = data;
  },
  /**
   * add sorting
   *
   * @param {object} state
   * @param {string} data
   */
  addSorting(state, data) {
    if (config.sortingOptions[data]) {
      state.sorting = data;

      const [sortBy, direction = "ASC"] = config.sortingOptions[data];
      let currentSorting = null;
      if (sortBy && direction) {
        currentSorting = { sortBy, direction };
      }
      store.commit("category/addCurrentSorting", currentSorting);
    }
  },
  /**
   * set sorting
   *
   * @param {object} state
   * @param {string} data
   */
  setSorting(state, data) {
    if (config.sortingOptions[data]) {
      state.sorting = data;
      const [sortBy, direction = "ASC"] = config.sortingOptions[data];
      let currentSorting = null;
      if (sortBy && direction) {
        currentSorting = { sortBy, direction };
      }
      store.commit("category/setCurrentSorting", currentSorting);
    }
  },
  /**
   * add current sorting
   *
   * @param {object} state
   * @param {object} data
   */
  addCurrentSorting(state, data) {
    state.currentSorting = data;
  },
  /**
   * set current sorting
   *
   * @param {object} state
   * @param {object} data
   */
  setCurrentSorting(state, data) {
    state.currentSorting = data;
    store.commit("category/resetCurrentPage");
    store.dispatch("category/loadProductsByFilter");
  },
  /**
   * reset sorting
   *
   * @param {object} state
   * @param {array} data
   */
  resetSorting(state) {
    state.sorting = "default";
    state.currentSorting = null;
  },
  /**
   * set active filter
   *
   * @param {object} state
   * @param {object} data
   */
  setActiveFilter(state, data) {
    const obj = state.activeFilter.find((o) => {
      if (o.value == data.value) {
        return true; // stop searching
      }
    });
    if (obj == null) {
      state.activeFilter.push(data);
    }
    store.commit("category/resetCurrentPage");
    store.dispatch("category/loadCategoryFilters");
  },

  /**
   * remove active filter
   *
   * @param {object} state
   * @param {object} data
   */
  removeActiveFilter(state, data) {
    if (state.activeFilter.length > 0) {
      state.activeFilter.find((o, i) => {
        if (o.value == data.value) {
          state.activeFilter.splice(i, 1);
          return true;
        }
      });
      store.commit("category/resetCurrentPage");
      store.dispatch("category/loadCategoryFilters");
    }
  },
  /**
   * remove all active filter
   *
   * @param {object} state
   */
  removeAllActiveFilter(state) {
    state.activeFilter = [];
    store.commit("category/resetCurrentPage");
    store.commit("category/removePriceFilter");
    store.dispatch("category/loadCategoryFilters");
  },
};

const getters = {
  getCurrentCategory: (state) => state.current,
  getProductFilters: (state) => state.productFilters,
  getActiveFilter: (state) => state.activeFilter,
  getLoadProducts: (state) => state.loadProducts,
  getShowProducts: (state) => state.ShowProducts,
  getCurrentPage: (state) => state.currentPage,
  getProductsPerPage: (state) => state.prodsPerPage || 12,
  getTotalPages: (state) => state.totalPages,
  getTotalProducts: (state) => state.totalProducts,
  getPriceFilter: (state) => state.priceFilter,
  getPriceFilterMin: (state) => state.priceFilterMin,
  getPriceFilterMax: (state) => state.priceFilterMax,
  getIsPriceFilterActive: (state) =>
    !(state.priceFilter[0] === 0 && state.priceFilter[1] === 0) &&
    !(
      state.priceFilter[0] === state.priceFilterMin &&
      state.priceFilter[1] === state.priceFilterMax
    ),
  getSorting: (state) => state.sorting,
  getCurrentSorting: (state) => state.currentSorting,
  getBestSellersSimple: (state) => state.bestSellersSimple,
  getBestSellers: (state) => state.bestSellers,
  getSlider: (state) => state.slider,
  getGtmProducts: (state) => state.gtmProducts,
  getGa4Products: (state) => state.ga4Products,
  getGtmProductsCount: (state) => state.gtmProductsCount,
  getCurrentCategoryId: (state) => {
    if (state.current != null) {
      return state.current.id;
    } else {
      return 0;
    }
  },
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
