import store from '@storefront/core/store';
import menu from '@/base/store/modules/menu';
import home from '@/base/store/modules/home';
import sale from '@/base/store/modules/sale';
import blog from '@/base/store/modules/blog';
import product from '@/base/store/modules/product';
import checkout from '@/base/store/modules/checkout';
import stores from '@/base/store/modules/stores';
import slider from "@/base/store/modules/slider";
//import { isServer } from "@storefront/core/helpers";
store.registerModule('menu', menu);
store.registerModule('home', home);
store.registerModule('sale', sale);
store.registerModule('checkout', checkout);
store.registerModule('blog1', blog);
store.registerModule('product1', product); //change
store.registerModule('stores1', stores); // change
store.registerModule("categorySlider", slider);
export default store;
