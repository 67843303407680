const state = () => ({
  isBillingAddress: true,
  enteredShippingAddress: null,
  enteredBillingAddress: null,
  isStoreAddress: false,
  selectedStoreLocation: "",
});

const actions = {};

const mutations = {
  setIsBillingAddress(state, payload) {
    state.isBillingAddress = payload;
  },
  setEnteredShippingAddress(state, payload) {
    state.enteredShippingAddress = payload;
  },
  setEnteredBillingAddress(state, payload) {
    state.enteredBillingAddress = payload;
  },
  setIsStoreAddress(state, payload) {
    state.isStoreAddress = payload;
  },
  setSelectedStoreLocation(state, payload) {
    state.selectedStoreLocation = payload;
  },
};

const getters = {
  getIsBillingAddress: (state) => state.isBillingAddress,
  getEnteredShippingAddress: (state) => state.enteredShippingAddress,
  getEnteredBillingAddress: (state) => state.enteredBillingAddress,
  getIsStoreAddress: (state) => state.isStoreAddress,
  getSelectedStoreLocation: (state) => state.selectedStoreLocation,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
