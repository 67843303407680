const Home = () =>
  import(/* webpackChunkName: "sf-home" */ "../core/views/home/Home.vue");

const TempPage = () => import("../core/views/Temp.vue");

const CustomerService = () => import("../core/views/CustomerService.vue");

const TermsAndConditions = () => import("../core/views/TermsAndConditions.vue");

const AddProductReview = () =>
  import("../core/views/product-review/AddReview.vue");

const ProductReviews = () =>
  import("../core/views/product-review/ReviewList.vue");

const SubscribeNewsletter = () =>
  import("../core/views/subscribe-newsletter/SubscribeNewsletter.vue");

const PageNotFound = () =>
  import(
    /* webpackChunkName: "sf-page-not-found" */ "../core/views/page-not-found/PageNotFound.vue"
  );

const Category = () =>
  import(
    /* webpackChunkName: "sf-category-page" */ "../core/views/products/category-product/Category.vue"
  );

const Login = () =>
  import(/* webpackChunkName: "sf-login" */ "../core/views/auth/Login.vue");

const ForgotPassword = () =>
  import(
    /* webpackChunkName: "sf-forgot-password" */ "../core/views/auth/ForgotPassword.vue"
  );

const ResetPassword = () =>
  import(
    /* webpackChunkName: "sf-forgot-password" */ "../core/views/auth/ResetPassword.vue"
  );

const CreateAccount = () =>
  import(
    /* webpackChunkName: "sf-create-account" */ "../core/views/auth/CreateAccount.vue"
  );

const Account = () =>
  import(
    /* webpackChunkName: "sf-create-account" */ "../core/views/account/Account.vue"
  );

const CmsPage = () =>
  import(
    /* webpackChunkName: "sf-cms-page" */ "../core/views/StaticPage1column.vue"
  );

// const Folder = () =>
//   import(
//     /* webpackChunkName: "sf-cms-page" */ '../core/views/Folder1column.vue'
//   );

const Product = () =>
  import(
    /* webpackChunkName: "sf-product" */ "../core/views/products/product-detail/Product.vue"
  );

const Cart = () =>
  import(/* webpackChunkName: "sf-cart" */ "../core/views/cart/Cart.vue");

const Compare = () =>
  import(
    /* webpackChunkName: "sf-our-shop" */ "../core/views/compare/Compare.vue"
  );

const Checkout = () =>
  import(
    /* webpackChunkName: "sf-checkout" */ "../core/views/checkout/Checkout.vue"
  );

const PaymentSuccess = () =>
  import(
    /* webpackChunkName: "sf-payment-success" */ "../core/views/payment/PaymentSuccess.vue"
  );

const PaymentCancel = () =>
  import(
    /* webpackChunkName: "sf-payment-cancel" */ "../core/views/payment/PaymentCancel.vue"
  );

const OurShops = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/OurShops.vue");

const OurShop = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/OurShop.vue");

const Brands = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Brands.vue");

//const Brand = () =>
//  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Brand.vue");

const Search = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Search.vue");

const Sale = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Sale.vue");

//const Faq = () =>
//import(/* webpackChunkName: "sf-our-shop" */ "../core/views/faq/Faq.vue");

const Contact = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/Contact.vue");

const Blogs = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/blog/Blogs.vue");
const Blog = () =>
  import(/* webpackChunkName: "sf-our-shop" */ "../core/views/blog/Blog.vue");

const AllCategories = () =>
  import(
    /* webpackChunkName: "sf-our-shop" */ "../core/views/categories/AllCategories.vue"
  );

const routes = [
  { name: "home", path: "/", component: Home },
  { name: "login", path: "/login", component: Login },
  {
    name: "forgot-password",
    path: "/forgot-password",
    component: ForgotPassword,
  },
  {
    name: "reset-password",
    path: "/customer/account/createPassword",
    component: ResetPassword,
  },
  { name: "create-account", path: "/create-account", component: CreateAccount },
  { name: "account", path: "/account", component: Account },
  { name: "product-SimpleProduct", path: "/product", component: Product },
  { name: "cms-page", path: "/cms-page", component: CmsPage },
  { name: "cart", path: "/checkout/cart", component: Cart },
  { name: "compare", path: "/compare", component: Compare },
  { name: "checkout", path: "/checkout", component: Checkout },
  { name: "ourshops", path: "/onze-winkels", component: OurShops },
  { name: "ourshop", path: "/onze-winkels/:code", component: OurShop },
  { name: "search", path: "/search", component: Search },
  { name: "sale", path: "/sale", component: Sale },
  { name: "brands", path: "/merken", component: Brands },
  {
    name: "blogs",
    path: "/blog",
    component: Blogs,
  },
  {
    name: "blog-category",
    path: "/blog/categorie/:category_id",
    component: Blogs,
  },
  { name: "blog", path: "/blog/:code", component: Blog },
  //{ name: "brand", path: "/merken/:code", component: Brand },
  {
    name: "onpagesuccess",
    path: "/checkout/onepage/success",
    component: PaymentSuccess,
  },
  {
    name: "onpagecancel",
    path: "/checkout/onepage/cancel",
    component: PaymentCancel,
  },
  {
    name: "multisafepaysuccess",
    path: "/multisafepay/connect/success",
    component: PaymentSuccess,
  },
  {
    name: "multisafepaycancel",
    path: "/multisafepay/connect/cancel",
    component: PaymentCancel,
  },

  { name: "category-page", path: "/category-page", component: Category },
  {
    name: "product-ConfigurableProduct",
    path: "/productconfig",
    component: Product,
  },
  {
    name: "product-BundleProduct",
    path: "/productbundle",
    component: Product,
  },
  {
    name: "product-page",
    path: "/product",
    component: Product,
  },

  {
    name: "product-LookProduct",
    path: "/productlook",
    component: Product,
  },
  /*{
    name: "faq",
    path: "/klantenservice",
    component: Faq,
  },*/

  {
    name: "contact",
    path: "/contact",
    component: Contact,
  },
  { name: "temp", path: "/temp", component: TempPage },
  {
    name: "CustomerService",
    path: "/klantenservice",
    component: CustomerService,
  },
  {
    name: "TermsAndConditions",
    path: "/terms-and-conditions",
    component: TermsAndConditions,
  },
  {
    name: "SubscribeNewsletter",
    path: "/subscribe-newsletter",
    component: SubscribeNewsletter,
  },
  {
    name: "AddProductReview",
    path: "/add-review",
    component: AddProductReview,
  },
  {
    name: "AllReviews",
    path: "/reviews",
    component: ProductReviews,
  },
  {
    name: "AllCategories",
    path: "/ons-assortiment",
    component: AllCategories,
  },
  // {
  //   name: 'Folder',
  //   path: '/folder',
  //   component: Folder,
  // },

  // error
  { name: "error", path: "/error", component: PageNotFound },

  // 404
  { name: "page-not-found", path: "/page-not-found", component: PageNotFound },
];

export default routes;
