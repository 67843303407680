import { Logger } from "@storefront/core/lib/logger";
import { getProductByCategoryId } from "@storefront/core/data-resolver/products";
import config from "@config";

const state = () => ( {
  Bundles: [],
  TopWeekProducts: [],
} );

const actions = {
  async loadBundles( { commit } ) {
    const products = await getProductByCategoryId( config.bundleList, 1 ).catch(
      ( e ) => {
        Logger.error( "loadMenu", "menu.ts", e )();
        throw e;
      }
    );
    commit( "setBundleProducts", products.items );
  },
  async loadTopWeekProducts( { commit } ) {
    /* static */
    const products = await getProductByCategoryId( config.categories.best_seller, 1 ).catch( ( e ) => {
      Logger.error( "loadMenu", "menu.ts", e )();
      throw e;
    } );
    commit( "setTopWeekProducts", products.items );
  },
};

const mutations = {
  setBundleProducts( state, payload ) {
    state.Bundles = payload;
  },
  setTopWeekProducts( state, payload ) {
    state.TopWeekProducts = payload;
  },
};

const getters = {
  getBundleProducts: ( state ) => state.Bundles,
  getRandomBundles: ( state ) => ( nr ) => {
    const bundles = JSON.parse( JSON.stringify( state.Bundles ) );
    const shuffled = bundles.sort( () => 0.5 - Math.random() );
    if ( nr > bundles.length ) {
      nr = bundles.length;
    }
    // Get sub-array of first n elements after shuffled
    return shuffled.slice( 0, nr );
  },
  getTopWeekProducts: ( state ) => state.TopWeekProducts,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
