import axios from "axios";
import config from "@config";
import { getCurrentLanguage } from "@storefront/core/i18n";
import { Logger } from "@storefront/core/lib/logger";

const state = () => ({
  stores: [], // shown product
});

const actions = {
  async load({ commit, dispatch }) {
    const page = await dispatch("loadStores").catch((e) => {
      Logger.error("stores", "store", e)();
      throw e;
    });
    commit("setStores", page);
    return page;
  },
  async loadStores() {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };

    const query =
      "{phisicalStores{ items { name url_key pickup_location_code street postcode city country_id phone fax email description banner_image {url label} map_image {url label} base_image {url label} storefront_image {url label} images {url label} opening_hours {day, label, times {from to} } alternate_opening_hours {date, times{from to} reason } manager { name role image } } }}";

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      Logger.error("loadContentPageByIdentifier", "data-resolver cms", e)();
      throw e;
    });

    if (retval.data.data.phisicalStores != null) {
      return retval.data.data.phisicalStores.items;
    } else {
      return false;
    }
  },
};

const mutations = {
  // always and only change vuex state through mutations.
  setStores(state, data) {
    const d = new Date();

    const curday = d.getDay();

    data.forEach((store, index) => {
      const openingHours = [];
      store.opening_hours.forEach((day) => {
        const today = new Date();
        let adddays = day.day;

        if (day.day < curday) {
          adddays = 7 + day.day;
        }

        const searchdate = new Date();

        searchdate.setDate(today.getDate() + adddays - curday);

        const dateComp =
          searchdate.getFullYear() +
          "-" +
          String(searchdate.getMonth() + 1).padStart(2, "0") +
          "-" +
          String(searchdate.getDate()).padStart(2, "0");

        if (day.day == 0) {
          day.day = 7;
        }
        let label = day.label;
        let hours = day.times;
        const special = store.alternate_opening_hours.find((v) => {
          if (v.date == dateComp) {
            return true;
          }
        });
        if (special != null) {
          if (special.reason != null) {
            label = special.reason;
          }
          hours = [special.times];
        }

        const openingHour = { label: label, hours: hours };

        openingHours[day.day - 1] = openingHour;
      });
      data[index].openingHours = openingHours;
    });
    state.stores = data;
  },
};

const getters = {
  getStores: (state) => state.stores,
  getStoreByCode: (state) => (code) =>
    state.stores.find(
      (item) => typeof item === "object" && item.url_key === code
    ),
  /*
  getOpeningTimes: (state) => (code) => {
    const store = state.stores.find(
      (item) =>
        typeof item === "object" && item.pickup_location_code === code
    );
    const d = new Date();
    //const curday = d.getDay();
    //const openingHours = [];
    //store.opening_hours.forEach((element) => {});
  },*/
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
