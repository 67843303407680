import axios from "axios";
import config from "@config";
import { Logger } from "@storefront/core/lib/logger";
import { getProductDetails } from "@storefront/core/data-resolver/products";
import { getCurrentLanguage } from "@storefront/core/i18n";

const state = () => ({
  currentCustomMeasures: [],
  servicePostcode: []
});

const actions = {
  async searchBeforeEnter(_, filter) {

    const lang = getCurrentLanguage();
    //Needs to become variable based on language
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };


    const query =
      '{search_redirect(search: "' +
      filter +
      '") {   redirect_url  }}';
    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
    }).catch((e) => {
      // Logger.error("getProductsBySearchPreview", "data-resolver products", e)();
      throw e;
    });

    return retval.data.data.search_redirect;
  },
  async checkExtraService(_, postalCode) {
    const lang = getCurrentLanguage();
    const storelang = config.languages[lang];
    const storeview = storelang["storeview"];

    const headers = {
      "Content-Type": "application/json",
      Authorization: "Bearer " + config.shop.accessToken,
      Store: storeview,
    };

    const query =
      'query {postcodeServicesAvailability(postcode: "' +
      postalCode +
      '"){is_available}}';

    const retval = await axios({
      url: config.shop.graphQLURL + "?query=" + encodeURI(query),
      method: "GET",
      headers: headers,
      //data: { query: query },
    }).catch((e) => {
      Logger.error("order", "data-resolver carts", e)();
      console.log(e);
      throw e;
    });

    if (retval.data.data.postcodeServicesAvailability != null) {
      return retval.data.data.postcodeServicesAvailability;
    } else {
      return false;
    }
  },

  async loadConfigProduct({ commit }, { sku, type }) {
    const products = await getProductDetails(sku, type).catch((e) => {
      Logger.error("getProductDetails", "product store actions load", e)();
      throw e;
    });
    const product = products.items[0];
    commit("setCurrentConfig", product);

    const prodOptions = [];
    if (product.configurable_options.length == 1) {
      const values = [];
      product.variants.forEach((element) => {
        const value = {
          label: element.attributes[0].label,
          value_index: element.product.sku,
          price: element.product.price_range.minimum_price.final_price.value.toFixed(
            2
          ),
        };
        values.push(value);
      });
      const prodOption = {
        index: 0,
        attribute_code: product.configurable_options[0].attribute_code,
        label: product.configurable_options[0].label,
        id: product.configurable_options[0].id,
        choice: null,
        values: values,
      };
      prodOptions.push(prodOption);

      if (product.configurable_options[0].attribute_code == "fkv_maten") {
        const cvalues = [];
        if (product.size_chart != null) {
          if (product.size_chart.sizes.length != null) {
            product.variants.forEach((element) => {
              const found = product.size_chart.sizes.find((v) => {
                if (v.from.option_id == element.attributes[0].value_index) {
                  return true;
                }
              });
              if (found != null) {
                const cvalue = {
                  label: found.to.label,
                  value_index: element.product.sku,
                };
                cvalues.push(cvalue);
              }
            });
          }
        }
        commit("setCurrentCustomMeasures", cvalues);
      }
    } else {
      product.configurable_options.forEach((option, index) => {
        if (index == 0) {
          const prodOption = {
            index: index,
            attribute_code: option.attribute_code,
            label: option.label,
            id: option.id,
            choice: null,
            values: option.values,
          };
          prodOptions.push(prodOption);
          if (option.attribute_code == "fkv_maten") {
            const cvalues = [];
            if (product.size_chart.sizes.length != null) {
              option.values.forEach((element) => {
                const found = product.size_chart.sizes.find((v) => {
                  if (v.from.option_id == element.value_index) {
                    return true;
                  }
                });
                if (found != null) {
                  const cvalue = {
                    label: found.to.label,
                    value_index: element.value_index,
                  };
                  cvalues.push(cvalue);
                }
              });
              commit("setCurrentCustomMeasures", cvalues);
            }
          }
        } else {
          const prodOption = {
            index: index,
            attribute_code: option.attribute_code,
            label: option.label,
            choice: null,
            id: option.id,
            values: [],
          };
          prodOptions.push(prodOption);
        }
      });
    }
    commit("setCurrentOptions", prodOptions);
    commit("setCurrentChildSku", null);
  },
};

const mutations = {
  setCurrentCustomMeasures(state, data) {
    state.currentCustomMeasures = data;
  },
  setOptionValue(state, data) {
    for (let i = data.index + 1; i < state.currentOptions.length; i++) {
      if (state.currentOptions[i].attribute_code == "fkv_maten") {
        state.currentCustomMeasures = [];
      }
      state.currentOptions[i].choice = null;
      state.currentOptions[i].values = [];
    }
    if (data.index == state.currentOptions.length - 1) {
      state.currentChildSku = data.value;
    } else {
      state.currentOptions[data.index].choice = data.value;
      state.currentChildSku = null;
      let products = state.currentConfig.variants;
      for (let i = 0; i <= data.index; i++) {
        products = products.filter(function (item) {
          const retval = item.attributes.find((o) => {
            if (o.value_index == state.currentOptions[i].choice) {
              return true;
            }
          });
          if (retval != null) {
            return true;
          }
        });
      }
      if (data.index == state.currentOptions.length - 2) {
        const values = [];
        const cvalues = [];
        products.forEach((element) => {
          const retval = element.attributes.find((o) => {
            if (o.code == state.currentOptions[data.index + 1].attribute_code) {
              return true;
            }
          });
          const value = {
            label: retval.label,
            value_index: element.product.sku,
          };
          values.push(value);

          if (retval.code == "fkv_maten") {
            if (state.currentConfig.size_chart.sizes.length != null) {
              const found = state.currentConfig.size_chart.sizes.find((v) => {
                if (v.from.option_id == retval.value_index) {
                  return true;
                }
              });
              if (found != null) {
                const cvalue = {
                  label: found.to.label,
                  value_index: element.product.sku,
                };
                cvalues.push(cvalue);
              }
            }
          }
        });
        state.currentOptions[data.index + 1].values = values;
        state.currentCustomMeasures = cvalues;
      } else {
        //
      }
    }
  },
  setServicePostcode(state, data) {
    state.servicePostcode = data;
  },
};

const getters = {
  getCurrentCustomMeasures: (state) => state.currentCustomMeasures,
  getServicePostcode: (state) => state.servicePostcode,
};

// export this module.
export default {
  namespaced: true,
  state,
  actions,
  mutations,
  getters,
};
